<template>
  <div class="control" :style="style">
    <div ref="chart" :style="chartContainerStyle"></div>
  </div>
</template>

<script>
import {isEqual, debounce} from "lodash";
// import echarts from "echarts";
import SynopticDataValueBase from "./synoptic-data-value-base.vue";

export default {
  name: "SynopticGauge",
  extends: SynopticDataValueBase,
  computed: {
    isDirty() {
      return false;
    },
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    rotation() {
      return this?.control?.synopticComponent?.rotation || 0;
    },
    size() {
      return {
        height: this.currentRect.height,
        width: this.currentRect.width
      };
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
    },
    chartContainerStyle() {
      return {
        width: `${this.size.width}px`,
        height: `${this.size.height}px`
      };
    },
    showLabel() {
      return this?.control?.synopticComponent?.label
        ? this?.control?.synopticComponent?.label?.show
        : this?.control?.format != ""
        ? true
        : false;
    },
    labelOffset() {
      const os = this?.control?.synopticComponent?.label?.offset || [0, "50%"];
      return [os[0] || 0, os[1] || 0];
    },
    result() {
      const type =
        this.min === undefined ||
        this.max === undefined ||
        this?.control?.synopticComponent?.interval?.source == "percentage"
          ? "percentage"
          : "constant";
      const vlr =
        this.$utils.trim(this.expression) !== ""
          ? this.evaluate()
          : this.rawValue;
      return {
        type: type,
        min: this.min ?? 0,
        max: this.max ?? 100,
        value:
          type == "percentage" ? this.calcPerc(vlr, 0, 100) : parseFloat(vlr)
      };
    },
    chartOptions() {
      let bar = this?.control?.synopticComponent.bar || {};

      // color scale
      let semi_arcs = null,
        vlr = null;
      if (this?.control?.synopticComponent.fill == "none") {
        semi_arcs = [[1, bar?.color || "#3F51B5"]];
      } else {
        semi_arcs = (
          this?.control?.synopticComponent?.interval?.items || []
        ).map((item) => {
          vlr = this.limit(item);
          vlr = parseFloat(vlr ?? item.value);
          if (this.result.type != "percentage") {
            vlr = this.calcPerc(vlr);
          }
          vlr = vlr / 100;
          return [vlr, item.color];
        });
      }

      // text
      let baseStyle = this?.control?.synopticComponent?.style || {};
      let detail = {
        show: this.showLabel,
        valueAnimation: true,
        fontSize: this.$utils.asNumber(baseStyle["font-size"] || "") || 12,
        fontWeight:
          this.$utils.asNumber(baseStyle["font-weight"] || "") || "bold",
        fontStyle: baseStyle["font-style"] || "normal",
        textDecoration: baseStyle["text-decoration"] || "none",
        color: baseStyle["color"] || "auto",
        offsetCenter: this.labelOffset,
        formatter: () => {
          return this.formattedValue;
        }
      };

      // ticks
      let tick = this?.control?.synopticComponent?.tick || {};

      // pointer
      let pointer = this?.control?.synopticComponent?.pointer || {};

      // scale
      let scale = this?.control?.synopticComponent?.scale || {};

      const echart_version = "4";

      return {
        backgroundColor: "transparent",
        series: [
          {
            name: "-",
            type: "gauge",
            min: this.result.min,
            max: this.result.max,
            splitNumber: parseInt(scale.show ? scale.nElements : -1),
            startAngle: bar.angleIni,
            endAngle: bar.angleEnd,
            data: [
              {
                value: this.result.value || 0
              }
            ],
            axisLabel: {
              fontWeight: scale.bold ? "bolder" : "normal",
              fontSize: scale.width,
              color: scale.color,
              formatter: (v) => {
                if ((v * 10) % 10) {
                  return this.$utils.sprintf(`%.${scale.precision || 0}f`, v);
                } else {
                  return this.$utils.sprintf("%0d", v);
                }
              }
            },
            pointer: {
              show: pointer.show,
              width: pointer.width || 10,
              length: `${pointer.height}%`,
              itemStyle: {
                color: pointer.color
              }
            },
            axisTick: {
              show: tick.show,
              distance: tick.offset,
              length: tick.height,
              lineStyle: {
                color: tick.color,
                width: tick.width
              }
            },
            splitLine: {
              show: tick.show,
              distance: scale.offset,
              length: scale.height,
              lineStyle: {
                color: echart_version == "4" ? "transparent" : scale.color,
                width: echart_version == "4" ? 0 : scale.width
              }
            },
            axisLine: {
              show: bar.show,
              roundCap: false,
              lineStyle: {
                width: bar.width || 20,
                color: semi_arcs
              }
            },
            detail: detail,
            progress: {
              show: false,
              width: 8
            }
          }
        ]
      };
    }
  },
  watch: {
    size: {
      handler(n, o) {
        let chart = this.getChart();
        if (n && chart && !isEqual(n, o)) {
          this.$nextTick(() => {
            chart.resize();
          });
        }
      },
      deep: true,
      immediate: true
    },
    chartOptions: {
      handler(n) {
        if (n) this._delayedUpdate();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getChart() {
      let el = this.$refs?.chart || null;
      return el ? window.echarts.getInstanceByDom(el) : null;
    },
    createChart() {
      let el = this.$refs?.chart || null;
      if (el) {
        let prv = this.getChart();
        if (prv) {
          prv.clear();
        }
        window.echarts
          .init(el, null, {renderer: "svg"})
          .setOption(this.chartOptions);
      }
    }
  },
  mounted: function() {
    this.$emit("hasContent", true);
    this.createChart();
  },
  beforeCreate() {
    this._delayedUpdate = debounce(() => {
      if (!this.chartOptions) return;
      let chart = this.getChart();
      if (chart) {
        chart.setOption(this.chartOptions);
      }
    }, 250);
  }
};
</script>

<style scoped>
.control {
  box-sizing: content-box;
}
.chart {
}
</style>
